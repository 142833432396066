import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import NavNewDark from "../NavBar/NavNewDark";
import NavNew from "../NavBar/NavNew";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import bannerbackground from "../../assets/bannerbackground.jpg";
import { width } from "@mui/system";
import Nav from "../NavBar/Nav";
import AsyncSelect from "react-select/async";
import {
  removeClaimFormTraveler,
  setClaimFormData,
} from "../Reducers/claimFormSlice";
import AgreementModal from "../Agreement/AgreementModal";
import DocuSignModal from "../Agreement/DocuSignModal";
import PowerModal from "../Agreement/PowerModal";
import PowerOfAttorney from "../Agreement/PowerOfAttorney";

const ClaimForm = () => {
  const [familyMembers, setFamilyMembers] = useState([]);
  const powerData = useSelector((state) => state.claimForm);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const powerRef = useRef(null);

  const [backdropopen, setbackdropopen] = useState(false);
  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);
  const dispatch = useDispatch();

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultAirlineOptions, setDefaultAirlineOptions] = useState([]);

  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestOption, setSelectedDestOption] = useState(null);
  const [selectedAirlineOption, setSelectedAirlineOption] = useState(null);
  const [selectedHomeOption, setSelectedHomeOption] = useState(null);
  const [signUrl, setSignUrl] = useState(null);

  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [hourStatus, setHourStatus] = useState(false);
  const [minStatus, setMinStatus] = useState(false);

  const [optionsList, setOptionsList] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);
  const [selectedAdditionalFiles, setSelectedAdditionalFiles] = useState(null);
  const [selectedPassportSubClaim, setSelectedPassportSubClaim] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isPowerOfAttorneyChecked, setIsPowerOfAttorneyChecked] =
    useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [signatureCaptured, setSignatureCaptured] = useState(false);
  const [isTermsLinkClicked, setIsTermsLinkClicked] = useState(false);
  const [isAgreementLinkClicked, setIsAgreementLinkClicked] = useState(false);
  const [isPowerLinkClicked, setIsPowerLinkClicked] = useState(false);
  const [envolpeId, setEnvelopeId] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isDocuSignModalOpen, setIsDocusignModalOpen] = useState(false);
  const [documentFetched, setDocumentFetched] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isDocumentFetching, setIsDocumentFetching] = useState(false);

  const handleTermsChange = (event) => {
    if (isTermsLinkClicked) {
      setIsTermsChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Terms and Conditions",
        text: "You must click on the Terms and Conditions link and read them before checking the checkbox.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const handleTermsLinkClick = () => {
    setIsTermsLinkClicked(true);
  };

  const handleSignatureAdded = (signatureData) => {
    //console.log("ok");
  };

  const handlePdfGenerated = (blob) => {
    setPdfBlob(blob);
    setSignatureCaptured(true); // Set state to show "View Agreement" button
  };

  // const handleTermsChange = () => {
  //   setIsTermsChecked(!isTermsChecked);
  // };

  const handleAgreementChange = (event) => {
    if (isAgreementLinkClicked) {
      setIsAgreementChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Agreement",
        text: "You must click on the Agreement link and read them before checking the checkbox.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePowerOfAttorneyChange = (event) => {
    if (isPowerLinkClicked) {
      setIsPowerOfAttorneyChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Power of Attorney",
        text: "You must click on the Power of Attorney link and read it before proceeding.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const fetchSignedDocument = () => {
    setbackdropopen(true);

    axios
      .get(`${base_url.api1}/tourapp/get_signed_doc?id=${envolpeId}`)
      .then((response) => {
        if (response.data && response.data.Document) {
          const base64Document = response.data.Document;
          const pdfBlob = base64ToBlob(base64Document, "application/pdf");

          setPdfBlob(pdfBlob);
          setSignatureCaptured(true);
          setDocumentFetched(true);
        } else {
          console.error("Signed document is not present in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching signed document:", error);
      })
      .finally(() => {
        setbackdropopen(false);
      });
  };

  // const startFetching = () => {
  //   if (intervalId) clearInterval(intervalId);
  //   const id = setInterval(() => {
  //     if (!isFetching) {
  //       fetchSignedDocument();
  //     }
  //   }, 10000);
  //   setIntervalId(id);
  // };

  // useEffect(() => {
  //   if (envolpeId && !documentFetched) {
  //     startFetching();
  //   }
  //   return () => {
  //     if (intervalId) clearInterval(intervalId);
  //   };
  // }, [envolpeId, isFetching, documentFetched]);

  // const handleReFetch = () => {
  //   setDocumentFetched(false);
  //   startFetching();
  // };
  const handleIframeClose = () => {
    fetchSignedDocument();
  };

  const isFormValid =
    isTermsChecked &&
    isAgreementChecked &&
    (familyMembers.length > 0 ? isPowerOfAttorneyChecked : true);

  const handleInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [fieldName]: value,
      };
      return updatedMembers;
    });
  };

  const addFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      {
        first_name: null,
        last_name: null,
        date_of_birth: null,
        passport_number: null,
        ticket_number: null,
        passport_file: null, // Initialize as null or handle file object
        passport_name: null,
      },
    ]);
  };
  const [ticketNumberErrors, setTicketNumberErrors] = useState(
    Array(familyMembers.length).fill("")
  );

  const handleTicketNumberChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "");
    let errorMessage = "";
    if (value.length > 13) {
      return;
    }

    if (!value) {
      errorMessage = "Ticket Number is Required";
    } else if (value.length !== 13) {
      errorMessage = "Ticket Number must be 13 digits";
    }
    const updatedErrors = [...ticketNumberErrors];
    updatedErrors[index] = errorMessage;
    setTicketNumberErrors(updatedErrors);

    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        ticket_number: value,
      };
      return updatedMembers;
    });
  };

  const handlePassportUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedFamilyMember = [...familyMembers];
    updatedFamilyMember[index]["passport_file"] = file;
    updatedFamilyMember[index]["passport_name"] = `sub_claim_${index + 1}`;
    setFamilyMembers(updatedFamilyMember);
  };

  const removeFamilyMember = (indexToRemove) => {
    const updatedFamilyMembers = familyMembers.filter(
      (_, index) => index !== indexToRemove
    );
    setFamilyMembers(updatedFamilyMembers);
    dispatch(removeClaimFormTraveler(indexToRemove));
  };

  const getCurrentDateTime = () => {
    return moment().format("h:mm A MMMM D, YYYY");
  };

  const addCustomerNote = (customerNote, notes) => {
    const trimmedNote = customerNote.trim();

    // Don't add the note if it's empty, null, or only contains spaces
    if (!trimmedNote) {
      return null;
    }

    const dateTime = getCurrentDateTime();
    const newNote = {
      user: "customer",
      note: trimmedNote,
      timestamp: dateTime,
    };

    return notes ? [newNote, ...notes] : [newNote];
  };

  const fetchSuggestions = async (inputValue) => {
    if (!inputValue) {
      setOptionsList([]);
      return;
    }

    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
      );
      const data = response.data;
      const suggestions = data.suggestions.map((suggestion) => ({
        label: suggestion.postal_code
          ? `${suggestion.description},${suggestion.postal_code}`
          : suggestion.description,
        value: suggestion.place_id,
      }));
      setOptionsList(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setOptionsList([]);
    }
  };

  const [mobile, setMobile] = useState();
  const [ticket, setTicket] = useState();

  const handleTicketInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 13) {
      return;
    }
    setTicket(value);
    setValue("tickets", value, { shouldValidate: true });
  };

  const handleMobileInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 10) {
      return;
    }
    setMobile(value);
    setValue("contact_no", value, { shouldValidate: true });
  };

  const handleAddressInputChange = (e) => {
    fetchSuggestions(e.target.value);
    setValue("address", e.target.value);
    setIsOpenList(true);
  };

  const handleAddressChange = (option) => {
    setValue("address", option.label);
    setOptionsList([]);
    setIsOpenList(false);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpenList(false);
    }, 100);
  };

  const handleHourChange = (event) => {
    setSelectedHour(event.target.value);
    //console.log(event.target.value);
    setHourStatus(false);
  };

  const handleMinuteChange = (event) => {
    setSelectedMinute(event.target.value);
    //console.log(event.target.value);
    setMinStatus(false);
  };
  // Fetch initial data
  useEffect(() => {
    fetchInitialAirports();
  }, []);

  const fetchAirlines = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airline_list?search=${inputValue}`
        );
        if (response.data && response.data.airlines) {
          return response.data.airlines.map((airline) => ({
            value: airline.id,
            label: airline.airline_name,
          }));
        }
      } catch (error) {
        console.error("Error fetching airlines:", error);
      }
    }
    return [];
  };

  const fetchHomeSuggestions = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
        );
        const data = response.data;
        return data.suggestions.map((suggestion) => ({
          label: suggestion.description,
          value: suggestion.description,
        }));
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        return [];
      }
    }
  };

  const handleAdditionalFileChange = (e) => {
    setSelectedAdditionalFiles(e.target.files);
  };

  const fetchAirports = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airport_list?search=${inputValue}`
        );
        if (response.data && response.data.Airports) {
          return response.data.Airports.map((airport) => ({
            value: airport.id,
            label: airport.airport_name,
          }));
        }
      } catch (error) {
        console.error("Error fetching airports:", error);
      }
    }
    return [];
  };

  const loadAirlineOptions = (inputValue, callback) => {
    fetchAirlines(inputValue).then((options) => callback(options));
  };

  const loadOptions = (inputValue, callback) => {
    fetchAirports(inputValue).then((options) => callback(options));
  };

  const fetchInitialAirports = async () => {
    try {
      // Fetch initial airline options
      const airlineResponse = await axios.get(
        `${base_url.api1}/tourapp/airline_list`
      );
      if (airlineResponse.data && airlineResponse.data.airlines) {
        const airlineOptions = airlineResponse.data.airlines.map((airline) => ({
          value: airline.id,
          label: airline.airline_name,
        }));
        setDefaultAirlineOptions(airlineOptions);
      }

      const response = await axios.get(`${base_url.api1}/tourapp/airport_list`);
      if (response.data && response.data.Airports) {
        const options = response.data.Airports.map((airport) => ({
          value: airport.id,
          label: airport.airport_name,
        }));
        setDefaultOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial airports:", error);
    }
  };

  const [pdfData, setPdfData] = useState(null);

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isPowerModalOpen, setIsPowerModalOpen] = useState(false);
  const [powerPdf, setPowerPdf] = useState(null);

  const openAgreementModal = () => {
    setIsAgreementLinkClicked(true);
    setAgreementModalOpen(true);
  };

  const getPowerOfAttorney = async () => {
    const content = powerRef.current;
    let htmlString = null;
    if (content) {
      htmlString = content.innerHTML;
    }
    setbackdropopen(true);

    try {
      const response = await axios.post(
        `${base_url.api1}/tourapp/power_of_attorney`,
        {
          html_string: htmlString,
        }
      );

      if (response.data && response.data.status && response.data.document) {
        const base64PDF = response.data.document;
        handlePowerPDF(base64PDF);
        setbackdropopen(false);
      }
    } catch (error) {
      console.error("Error fetching Power of Attorney PDF:", error);
      setbackdropopen(false);
    }
  };

  const handlePowerPDF = (base64PDF) => {
    const pdfBlob = base64ToBlob(base64PDF, "application/pdf");

    setPowerPdf(pdfBlob);

    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const openPowerOfAttorneyModal = () => {
    Swal.fire({
      title: "Important: Power of Attorney",
      html: `
        Please review the Power of Attorney document carefully. 
        Ensure you obtain consent from all other travelers listed. 
        After obtaining their signatures, kindly send the completed document to 
        <a href="mailto:claims@foglar.pl">claims@foglar.pl</a> 
        for further processing of your claim.
      `,
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      // Code to execute after the user clicks "OK"
      setIsPowerLinkClicked(true);
      // setIsPowerModalOpen(true);
      getPowerOfAttorney();
    });
  };

  const closePowerModal = () => setIsPowerModalOpen(false);
  const closeAgreementModal = () => setAgreementModalOpen(false);

  const handleCheckAgreement = () => {
    const formData = watch(); // Get current form data
    const todayDate = moment().format("DD-MM-YYYY");

    // Check required form fields
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.address ||
      !formData.passport_type_number ||
      !formData.Airline ||
      !formData.flight_number ||
      !formData.date_of_travel ||
      !formData.contact ||
      !formData.date_of_birth
    ) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all required fields before agreeing to the terms.",
      });
      return false;
    }

    // Check family members' details
    if (familyMembers.length > 0) {
      const incompleteFamilyMembers = familyMembers.filter(
        (member) =>
          !member.first_name ||
          !member.last_name ||
          !member.date_of_birth ||
          !member.passport_number ||
          !member.ticket_number
      );

      if (incompleteFamilyMembers.length > 0) {
        Swal.fire({
          icon: "warning",
          title: "Incomplete Travelers Details",
          text: "Please fill in all required travelers details before agreeing to the terms.",
        });
        return false;
      }
    }

    // If all checks pass, proceed with storing the data
    const dataToStore = {
      date: todayDate,
      clientName: `${formData.first_name} ${formData.last_name}`,
      clientAddress: formData.address,
      passportNumber: formData.passport_type_number,
      airline: formData.airline,
      flightDetails: `${formData.flight_number} on ${formData.date_of_travel}`,
      clientEmail: `${formData.email}`,
      bookingNumber: `${formData.contact}`,
      clientBirthDate: `${formData.date_of_birth}`,
      familyMembers: familyMembers, // Pass the familymember array here
    };

    dispatch(setClaimFormData(dataToStore));

    return true;
  };

  const handleCheckPowerOfAttorney = () => {
    return handleCheckAgreement();
  };

  const onSubmit = (data) => {
    //console.log("Img--->", selectedPassportSubClaim);
    //console.log("Form Data", data);
    if (!pdfBlob) {
      Swal.fire({
        icon: "error",
        title: "Agreement need to be signed",
      });
      return;
    }

    if (!country1) {
      setCountryValid(true);
      return;
    }
    if (!selectedHour) {
      setHourStatus(true);
      return;
    }
    if (!selectedMinute) {
      setMinStatus(true);
      return;
    }
    let timeValue = "";
    if (selectedHour && selectedMinute) {
      timeValue = `${selectedHour}:${selectedMinute}`;

      //console.log(timeValue);
    }

    let delay = timeValue;
    const formData = new FormData();
    const notesArray = null; // or an empty array: []
    const updatedNotes = addCustomerNote(data.note, notesArray);

    const familyMembersData = familyMembers.map((member, index) => {
      const memberData = {
        first_name: member.first_name,
        last_name: member.last_name,
        date_of_birth: member.date_of_birth,
        passport_number: member.passport_number,
        tickets: member.ticket_number,
      };

      if (member.passport_file) {
        memberData.passport = member.passport_name;
        formData.append(member.passport_name, member.passport_file);
      }

      return memberData;
    });

    //console.log("Family Details", familyMembersData);

    const formObject = {
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      passport_number: data.passport_type_number,
      nationality: country1,
      date_of_birth: data.date_of_birth,
      airline: data.Airline,
      flight_number: data.flight_number,
      airpotr_of_origin: data.airport_origin,
      airpotr_of_destination: data.airport_destination,
      status: "pending", // Assuming status is always 'pending'
      Delay: delay,
      contact_no: data.contact,
      email_id: data.email,
      date_of_travel: data.date_of_travel,
      note: updatedNotes,
      tickets: data.tickets,
      sub_claims: familyMembersData,
      envelope_id: envolpeId,
    };

    // Convert the form object to JSON
    const jsonFormObject = JSON.stringify(formObject);

    // Append the JSON object to the FormData
    formData.append("data", jsonFormObject);

    // Append file fields
    formData.append("boarding_pass", data.boarding_pass[0] || null);
    formData.append("additional_exp", null);
    formData.append("passport", data.passport[0] || null);

    if (selectedAdditionalFiles) {
      for (let i = 0; i < selectedAdditionalFiles.length; i++) {
        formData.append("document", selectedAdditionalFiles[i]);
      }
    } else {
      formData.append("document", null);
    }
    if (pdfBlob) {
      const pdfFile = new File([pdfBlob], "agreement.pdf", {
        type: "application/pdf",
      });

      formData.append("agreement", pdfFile);
    }

    if (powerPdf) {
      const pdfFile = new File([powerPdf], "power_of_attorney.pdf", {
        type: "application/pdf",
      });

      formData.append("power_of_attorney", pdfFile);
    }

    setbackdropopen(true);

    axios.post(`${base_url.api1}/tourapp/compensation_request`, formData).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          setbackdropopen(false);
          //console.log("77------>", response.data.data.unique_code);
          Swal.fire({
            icon: "info",
            title: "Save Your Claim ID",
            html: `Your Claim ID is: <strong>${response?.data?.data?.contact_no}</strong>. Please save it for future reference.`,
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/thank-you-page", {
              state: { isClaimed: 1 },
            });
          });

          document.getElementById("myForm").reset();
        } else {
          setbackdropopen(false);
        }
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "There was a problem connecting to the server. Please try again later.",
        });

        document.getElementById("myForm").reset();
        setbackdropopen(false);
      }
    );
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
          <img
            src={bannerbackground}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" class="b-link">
                Linsoa – Fly N Claim
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" class="b-link active">
                File A Claim
              </Link>
            </ul>
          </div>
          <Row className="px-0">
            <Col lg={12} className="carosol_img">
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.first_name && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        {...register("last_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.last_name && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>
                      Home Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        {...register("address", {
                          required: "Home Address is Required",
                        })}
                        onChange={handleAddressInputChange}
                        placeholder="Enter Home Address"
                        className="search-input form-control"
                        type="text"
                        autoComplete="off"
                        onBlur={handleInputBlur}
                      />
                      <i
                        className="fa fa-map-marker home_icon"
                        style={{ position: "absolute", left: 15, top: 10 }}
                      />
                      {isOpenList && optionsList.length > 0 && (
                        <ul className="search-results">
                          {optionsList.map((option, index) => (
                            <li
                              key={index}
                              onClick={() => handleAddressChange(option)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                      {errors.address && (
                        <p className="errormessage">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Col md={4}>
                    <label>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={mobile}
                        className="form-control"
                        placeholder="Mobile Number"
                        type="text"
                        {...register("contact", {
                          required: "Mobile Number is required",
                          validate: (value) =>
                            value.length === 10 ||
                            "Mobile Number must be 10 digits",
                        })}
                        onChange={handleMobileInputChange} // Move onChange handler here
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.contact && (
                      <p className="errormessage">{errors.contact.message}</p>
                    )}
                  </Col>

                  <div className="col-md-4">
                    <label>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Email Address"
                        type="email"
                        {...register("email", {
                          required: "Email Address is Required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <i className="fa fa-envelope designi" />
                    </div>
                    {errors.email && (
                      <p className="errormessage">{errors.email.message}</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Date Of Birth<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        className="form-control"
                        {...register("date_of_birth", {
                          required: true,
                        })}
                        placeholder="DOB"
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                    {errors.date_of_birth && (
                      <p className="errormessage">Date Of Birth is Required</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Nationality<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <CountryDropdown
                        className="countrydropdown2 form-control underline-input"
                        id="tcountry"
                        defaultOptionLabel="- Select Country -"
                        value={country1}
                        onChange={(val) => {
                          setcountry(val);
                          setCountryValid(false);
                        }}
                      />
                      <i className="fas fa-globe designi" />
                    </div>
                    {countryValid ? (
                      <p className="errormessage">Country is Required</p>
                    ) : null}
                  </div>
                  <Col md={4}>
                    <label>
                      13 digit ticket Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={ticket}
                        className="form-control"
                        placeholder="Ticket Number"
                        type="text"
                        {...register("tickets", {
                          required: "Ticket Number is required",
                          validate: (value) =>
                            value.length === 13 ||
                            "Ticket Number must be 13 digits",
                        })}
                        onChange={handleTicketInputChange}
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.tickets && (
                      <p className="errormessage">{errors.tickets.message}</p>
                    )}
                  </Col>

                  <div className="col-md-4">
                    <label>
                      Passport Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Passport number"
                        type="text"
                        {...register("passport_type_number", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-id-card designi" />
                    </div>
                    {errors.passport_type_number && (
                      <p className="errormessage">
                        Passport Number is Required
                      </p>
                    )}
                  </div>

                  <div className="col-md-4"></div>
                </div>

                <p className="tour_head_text_3">Flight Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Airline Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <Controller
                        name="Airline"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <AsyncSelect
                            {...field}
                            cacheOptions
                            loadOptions={loadAirlineOptions} // Function to load options asynchronously
                            defaultOptions={defaultAirlineOptions} // Initial options fetched when component mounts
                            placeholder="Select Airline"
                            value={selectedAirlineOption}
                            onChange={(selectedAirlineOption) => {
                              setSelectedAirlineOption(selectedAirlineOption);
                              field.onChange(
                                selectedAirlineOption
                                  ? selectedAirlineOption.label
                                  : ""
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                    {errors.Airline && (
                      <p className="errormessage">Airline is Required</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Flight Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Flight Number"
                        type="text"
                        {...register("flight_number", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.flight_number && (
                      <p className="errormessage">Flight Number is Required</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Date Of Travel<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        className="form-control"
                        {...register("date_of_travel", {
                          required: true,
                        })}
                        placeholder="Date of Travel"
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                    {errors.date_of_travel && (
                      <p className="errormessage">Date of Travel is Required</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Airport of origin
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <Controller
                        name="airport_origin"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <AsyncSelect
                            {...field}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions={defaultOptions}
                            placeholder="Airport of origin"
                            value={selectedOriginOption}
                            onChange={(selectedOriginOption) => {
                              setSelectedOriginOption(selectedOriginOption);
                              field.onChange(
                                selectedOriginOption
                                  ? selectedOriginOption.label
                                  : ""
                              );
                            }}
                          />
                        )}
                      />
                      {errors.airport_origin && (
                        <p className="errormessage">
                          Airport of Origin is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>
                      Airport of Destination
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <Controller
                        name="airport_destination"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <AsyncSelect
                            {...field}
                            cacheOptions
                            loadOptions={loadOptions} // Function to load options asynchronously
                            defaultOptions={defaultOptions} // Initial options fetched when component mounts
                            placeholder="Airport of Destination"
                            value={selectedDestOption}
                            onChange={(selectedDestOption) => {
                              setSelectedDestOption(selectedDestOption);
                              field.onChange(
                                selectedDestOption
                                  ? selectedDestOption.label
                                  : ""
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                    {errors.airport_destination && (
                      <p className="errormessage">
                        Airport of Destination is Required
                      </p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>
                      Delay (hours + minutes)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <div className="wrapper" style={{ gap: "20px" }}>
                        <select
                          className="form-control"
                          onChange={handleHourChange}
                          value={selectedHour}
                          style={{ width: "40%" }}
                        >
                          <option value="">HH</option>
                          {[...Array(24).keys()].map((hour) => (
                            <option
                              key={hour}
                              value={hour.toString().padStart(2, "0")}
                            >
                              {hour.toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-control"
                          onChange={handleMinuteChange}
                          value={selectedMinute}
                          style={{ width: "40%" }}
                        >
                          <option value="">MM</option>
                          {[...Array(60).keys()].map((minute) => (
                            <option
                              key={minute}
                              value={minute.toString().padStart(2, "0")}
                            >
                              {minute.toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                      </div>
                      <i className="fa fa-clock designi" />
                    </div>
                    <div className="wrapper">
                      {hourStatus && (
                        <p className="errormessage">Delay hours is Required</p>
                      )}
                      {minStatus && (
                        <p className="errormessage">
                          Delay minutes is Required
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <p className="tour_head_text_3">Upload Documents</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>Boarding Pass</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("boarding_pass", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Passport</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("passport", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Additional Documents</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        multiple
                        onChange={handleAdditionalFileChange}
                        className="form-control"
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>
                </div>

                <Button
                  color="primary"
                  onClick={addFamilyMember}
                  className="mt-3 mb-3"
                  style={{ maxWidth: "12%", minWidth: "12%" }}
                >
                  Add Traveler
                </Button>
                <div className="row">
                  {familyMembers.length > 0 && (
                    <div className="family-members-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              First Name<span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Last Name<span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Date Of Birth
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>Passport Number</th>
                            <th>
                              Ticket Number
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th style={{ width: "22%" }}>Passport</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {familyMembers.map((member, index) => (
                            <tr key={index}>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="form-control"
                                    placeholder="First Name"
                                    type="text"
                                    value={member.first_name}
                                    onChange={(e) =>
                                      handleInputChange(e, index, "first_name")
                                    }
                                    required
                                  />

                                  <i className="fa fa-user-o designi" />
                                </div>
                              </td>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="form-control"
                                    placeholder="Last Name"
                                    type="text"
                                    value={member.last_name}
                                    onChange={(e) =>
                                      handleInputChange(e, index, "last_name")
                                    }
                                    required
                                  />

                                  <i className="fa fa-user-o designi" />
                                </div>
                              </td>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="form-control"
                                    type="date"
                                    value={member.date_of_birth}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        index,
                                        "date_of_birth"
                                      )
                                    }
                                    required
                                  />

                                  <i className="fa fa-calendar designi" />
                                </div>
                              </td>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="form-control"
                                    placeholder="Passport Number"
                                    type="text"
                                    value={member.passport_number}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        index,
                                        "passport_number"
                                      )
                                    }
                                    required
                                  />
                                  <i className="fa fa-id-card designi" />
                                </div>
                              </td>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="form-control"
                                    placeholder="Ticket Number"
                                    type="text"
                                    value={member.ticket_number}
                                    onChange={(e) =>
                                      handleTicketNumberChange(e, index)
                                    }
                                    required
                                  />
                                  {ticketNumberErrors[index] && (
                                    <p className="errormessage">
                                      {ticketNumberErrors[index]}
                                    </p>
                                  )}
                                  <i className="fa fa-ticket designi" />
                                </div>
                              </td>
                              <td>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePassportUpload(e, index)
                                    }
                                  />
                                  <i className="fa fa-file-image-o designi" />
                                </div>
                              </td>
                              <td>
                                <Button
                                  color="danger"
                                  onClick={() => removeFamilyMember(index)}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <label>Note:</label>
                    <textarea
                      className="form-control form-control-sm"
                      {...register("note", {
                        required: false,
                      })}
                      style={{
                        minHeight: "150px",
                        fontSize: "18px",
                      }}
                      id="note"
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-column justify-content-start "
                  style={{ gap: "15px" }}
                >
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsCheckbox"
                      checked={isTermsChecked}
                      onChange={handleTermsChange}
                    />
                    <label
                      className="form-check-label mt-2"
                      htmlFor="termsCheckbox"
                      style={{ marginLeft: "12px" }}
                    >
                      I agree to the{" "}
                      <a
                        href="https://api.linsoatours.com/Images/other_document/FLT_Airline_claims_-_TC_1.pdf"
                        target="_blank"
                        onClick={handleTermsLinkClick}
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="agreementCheckbox"
                      checked={isAgreementChecked}
                      onChange={(e) => {
                        if (handleCheckAgreement()) {
                          handleAgreementChange(e);
                        }
                      }}
                    />
                    <label
                      className="form-check-label mt-2"
                      htmlFor="agreementCheckbox"
                      style={{ marginLeft: "12px" }}
                    >
                      I agree to the{" "}
                      <Link
                        onClick={() => {
                          if (handleCheckAgreement()) {
                            openAgreementModal();
                          }
                        }}
                      >
                        Agreement
                      </Link>
                    </label>
                    {signatureCaptured && (
                      <Button
                        onClick={() => {
                          if (pdfBlob) {
                            const url = URL.createObjectURL(pdfBlob);
                            window.open(url, "_blank");
                          }
                        }}
                        color="primary"
                        style={{
                          width: "200px",
                          marginLeft: "15px",
                        }}
                      >
                        View Agreement
                      </Button>
                    )}
                  </div>
                  {familyMembers.length > 0 && (
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="powerOfAttorneyCheckbox"
                        checked={isPowerOfAttorneyChecked}
                        onChange={(e) => {
                          if (handleCheckPowerOfAttorney()) {
                            handlePowerOfAttorneyChange(e);
                          }
                        }}
                      />
                      <label
                        className="form-check-label mt-2"
                        htmlFor="powerOfAttorneyCheckbox"
                        style={{ marginLeft: "12px" }}
                      >
                        I agree to the{" "}
                        <Link
                          onClick={() => {
                            if (handleCheckPowerOfAttorney()) {
                              openPowerOfAttorneyModal();
                            }
                          }}
                        >
                          Power of Attorney
                        </Link>
                      </label>
                    </div>
                  )}
                </div>
                <AgreementModal
                  isOpen={isAgreementModalOpen}
                  onRequestClose={closeAgreementModal}
                  onSignatureAdded={handleSignatureAdded}
                  onPdfGenerated={handlePdfGenerated}
                  setEnvelopeId={setEnvelopeId}
                  envolpeId={envolpeId}
                  signUrl={signUrl}
                  setSignUrl={setSignUrl}
                  setIsDocusignModalOpen={setIsDocusignModalOpen}
                  setIsFetching={setIsFetching}
                  setDocumentFetched={setDocumentFetched}
                />
                <PowerModal
                  isOpen={isPowerModalOpen}
                  onRequestClose={closePowerModal}
                />

                <DocuSignModal
                  isOpen={isDocuSignModalOpen}
                  onRequestClose={() => setIsDocusignModalOpen(false)}
                  signUrl={signUrl}
                  setSignUrl={setSignUrl}
                  handleIframeClose={handleIframeClose}
                />

                <div style={{ padding: "0 20px", marginBottom: "50px" }}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        style={{
                          padding: "10px 40px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        disabled={!isFormValid}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
          <div className="tourdetails_grid"></div>
        </div>
        <div style={{ display: "none" }}>
          <div ref={powerRef}>
            <PowerOfAttorney formData={powerData} />
          </div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
};

export default ClaimForm;
