import React, { useRef, useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { Box, Typography, Divider, Button } from "@mui/material";
import foglar from "../../assets/foglar.webp";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import DocuSignModal from "./DocuSignModal";
import base_url from "../../api/bootapi";
import axios from "axios";
import "./PowerContent.css";

const PowerContent = React.forwardRef((props, ref) => {
  const {
    date,
    clientName,
    clientAddress,
    passportNumber,
    bookingNumber,
    airline,
    flightDetails,

    clientEmail,

    setbackdropopen,
    onRequestClose,
    clientBirthDate,

    familyMembers,
  } = props;

  const contentRef = useRef();

  return (
    <div
      style={{
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        width: "793px",
        minHeight: "1122px",
        margin: "0 auto",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxSizing: "border-box",
      }}
      ref={ref}
      className={`print-container`}
    >
      <h1 style={{ textAlign: "center", marginBottom: "16px" }}>
        POWER OF ATTORNEY
      </h1>

      <p style={{ textAlign: "left", marginBottom: "16px" }}>{date}</p>

      <p>I/We, the undersigned,</p>

      <ol className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        {familyMembers.map((member, index) => (
          <li key={index}>
            {member.first_name} {member.last_name}, born {member.date_of_birth},
            passport number {member.passport_number}, ticket number{" "}
            {member.ticket_number}
          </li>
        ))}
      </ol>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        <strong>Hereby authorize:</strong>
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        {clientName}, born {clientBirthDate}, passport number {passportNumber} (
        <strong>“Representative”</strong>)
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        to pursue claims on my behalf against {airline} for compensation for
        flight cancellation/delay/refused entry.
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        Flight number: {flightDetails}
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        The power of attorney covers all claims (existing and potential) that I
        may have against the airline related to the above flight. The
        Representative may conduct all legal and factual actions on my behalf
        (including to assign my claims to Kevin Foglar conducting business under
        the name of Foglar Law Firm Kevin Foglar, registered at ul. Węgierska
        33p, 33-340 Stary Sącz, Poland, Polish NIP: 7343586718), supply
        necessary documents, and receive correspondence. The Representative may
        also receive compensation on my behalf.
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        The power of attorney should be interpreted broadly so as to cover all
        actions necessary to achieve its aim.
      </p>

      <p className={`${familyMembers.length > 2 ? "avoid-break" : ""}`}>
        The power of attorney is subject to Polish law.
      </p>

      <hr style={{ margin: "20px 0" }} />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {familyMembers.map((member, index) => (
          <div
            key={index}
            style={{
              flex: "1 1 45%",
              textAlign: "center",
              marginTop: "20px",
            }}
            className="avoid-break"
          >
            <div
              style={{
                width: "300px",
                height: "70px",
                margin: "0 auto",
                marginBottom: "10px",
              }}
            ></div>
            <p>_____________________________________</p>
            <p>
              {member.first_name} {member.last_name}
            </p>
          </div>
        ))}
      </div>

      <hr style={{ margin: "20px 0" }} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
        }}
        className={familyMembers.length > 3 ? "page-break" : ""}
      >
        <div style={{ flex: "1" }}>
          <p>Foglar Law Firm (NIP: 7343586718)</p>
        </div>
        <div style={{ flex: "1", textAlign: "center" }}>
          <p>www.foglar.pl</p>
        </div>
        <div style={{ flex: "1", textAlign: "right" }}>
          <p>claims@foglar.pl</p>
        </div>
      </div>
    </div>
  );
});

export default PowerContent;
