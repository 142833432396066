import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import base_url from "./../../api/bootapi";
import bannerbackground from "../../assets/bannerbackground.jpg";
import "./Navbar.css";

export default function Nav() {
  const [availableCategory, setAvailableCategory] = useState(null);

  const [upcomingTour, setupcomingTour] = useState([]);

  const [pastTour, setpastTour] = useState([]);

  useEffect(() => {
    getAllCategory();
    getAllTourList();
    getAllPastTourList();
  }, []);

  const getAllTourList = (value1) => {
    axios.get(`${base_url.api1}/tourapp/trip_list?status=Active`, {}).then(
      (response) => {
        //console.log(response.data.tour);
        setupcomingTour(response.data.tour);
        document.getElementById("myForm").reset();
      },
      (error) => {
        setupcomingTour([]);
        //console.log(JSON.stringify(error.response.data));
      }
    );
  };
  const getAllCategory = () => {
    axios
      .get(`${base_url.api1}/tourapp/all_category?status=1&sortby=id&Order=ASC`)
      .then(
        (response) => {
          setAvailableCategory(response.data.category);
        },
        (error) => {
          //console.log(JSON.stringify(error.message));
        }
      );
  };
  const getAllPastTourList = (value1) => {
    axios.get(`${base_url.api1}/tourapp/trip_list?status=Expired`, {}).then(
      (response) => {
        //console.log(response.data.tour);
        setpastTour(response.data.tour);
        document.getElementById("myForm").reset();
      },
      (error) => {
        setpastTour([]);
        //console.log(JSON.stringify(error.response.data));
      }
    );
  };

  return (
    <React.Fragment>
      <div className="new_main-header" style={{ zIndex: "3" }}>
        <div className="new_main-header-inn">
          <div className="new_head-menu">
            <nav className="nav" style={{ marginTop: "56px" }}>
              <input
                type="checkbox"
                id="nav__checkbox"
                className="nav__checkbox"
              />
              <label htmlFor="nav__checkbox" className="nav__toggle">
                <img
                  src="assets/image/menu-icon.png"
                  className="hamburger"
                  alt="Menu"
                />
                <img src="assets/image/x.png" className="close" alt="Close" />
              </label>
              <ul className="nav__menu">
                <li className="nav__item">
                  <Link
                    className="nav__link"
                    to="/"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav__item nav__dropdown">
                  <Link
                    className="nav__link"
                    to="#"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Linsoa Tours <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="nav__submenu" style={{ width: "150%" }}>
                    <li className="nav__submenu-item">
                      <Link to="#" className="nav__submenu-link">
                        Upcoming trips
                        <i
                          className="fa fa-chevron-right"
                          style={{ right: "5%", position: "absolute" }}
                        ></i>
                      </Link>
                      {upcomingTour.length > 0 && (
                        <ul
                          className={
                            upcomingTour.length > 0 && "nav__submenu-right"
                          }
                        >
                          {upcomingTour?.map((subMenu, subIndex) => (
                            <li
                              key={subIndex}
                              className="nav__submenu-right-item"
                            >
                              <Link
                                to={{
                                  pathname: `/view-tour-pdf/${subMenu.id}/`,
                                  state: {
                                    tourDetails: subMenu.tour_details,
                                    fromWhere: "upcoming_tour",
                                    tour_names: subMenu.tour_names,
                                  },
                                }}
                                className="nav__submenu-right-link"
                              >
                                {subMenu.tour_names}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li className="nav__submenu-item">
                      <Link to="#" className="nav__submenu-link">
                        Past trips{" "}
                        <i
                          className="fa fa-chevron-right"
                          style={{ right: "5%", position: "absolute" }}
                        ></i>
                      </Link>
                      {pastTour.length > 0 && (
                        <ul className={pastTour && "nav__submenu-right"}>
                          {pastTour?.map((subMenu, subIndex) => (
                            <li
                              key={subIndex}
                              className="nav__submenu-right-item"
                            >
                              <Link
                                to={{
                                  pathname: `/view-tour-pdf/${subMenu.id}/`,
                                  state: {
                                    tourDetails: subMenu.tour_details,
                                    fromWhere: "upcoming_tour",
                                    tour_names: subMenu.tour_names,
                                  },
                                }}
                                className="nav__submenu-right-link"
                              >
                                {subMenu.tour_names}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li className="nav__submenu-item">
                      {/* <a
                        href="https://wa.me/18453881104"
                        className="nav__submenu-link"
                      >
                        My Linsoa
                      </a> */}
                      <Link className="nav__submenu-link" to="/enquiry-status">
                        My Booking
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav__item nav__dropdown">
                  <Link
                    className="nav__link"
                    to="#"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Plan Your Trip <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="nav__submenu">
                    <li className="nav__submenu-item">
                      <Link to="/itinerary" className="nav__submenu-link">
                        Customized itinerary
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link className="nav__submenu-link" to="#">
                        Flights
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <a
                        href="https://www.expedia.co.in/Hotels"
                        className="nav__submenu-link"
                        target="_blank"
                      >
                        Hotels
                      </a>
                    </li>
                    <li className="nav__submenu-item">
                      <a
                        href="https://www.expedia.co.in/Hotels"
                        className="nav__submenu-link"
                        target="_blank"
                      >
                        H. Orchim
                      </a>
                    </li>
                    <li className="nav__submenu-item">
                      <Link to="/insurance" className="nav__submenu-link">
                        Travel Insurance
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link
                        to="#"
                        className="nav__submenu-link"
                        style={{ textAlign: "left" }}
                      >
                        Sightseeing & Interests
                      </Link>
                    </li>
                    {/* <li className="nav__submenu-item">
                      <Link className="nav__submenu-link" to="#">
                        My trip
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link className="nav__submenu-link" to="#">
                        My Photos and videos
                      </Link>
                    </li> */}
                  </ul>
                </li>
                {/* <li className="nav__item nav__dropdown">
                  <Link
                    className="nav__link"
                    to="#"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    My Trips <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="nav__submenu" style={{ width: "172%" }}>
                   
                  </ul>
                </li> */}
                <li className="nav__item nav__dropdown">
                  <Link
                    className="nav__link"
                    to="#"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Linsoa VIP transportation{" "}
                    <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="nav__submenu">
                    <li className="nav__submenu-item">
                      <Link className="nav__submenu-link" to="/vehicles">
                        The Fleet
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link className="nav__submenu-link" to="/vehicle-request">
                        Make a Booking
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link
                        to="/vehicle-booking-status"
                        className="nav__submenu-link"
                      >
                        My VIP Booking
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <img
                  src="https://apilinsoatours.odisoft.in/Images/Element/newlogo_dfPGKrl.png"
                  alt="Dynamic Image"
                  className="nav__logo"
                /> */}
                <li className="nav__item nav__dropdown">
                  <Link
                    className="nav__link"
                    to="#"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Linsoa – Fly N Claim <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="nav__submenu">
                    <li className="nav__submenu-item">
                      <Link to="/claim" className="nav__submenu-link">
                        File A Claim
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link to="/claimstatus" className="nav__submenu-link">
                        Claim Status
                      </Link>
                    </li>
                    <li className="nav__submenu-item">
                      <Link to="/info" className="nav__submenu-link">
                        Info And Disclaimer
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav__item">
                  <Link
                    className="nav__link"
                    to="/gallery"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    About Us-Gallery
                  </Link>
                </li>

                <li className="nav__item">
                  <Link
                    className="nav__link"
                    to="/contact"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div style={{ position: "relative", height: "25vh" }}>
        <img
          src={bannerbackground}
          alt="Header_Image"
          style={{ height: "100%", width: "100%" }}
        />
        <div className="overlaybg">
          <div style={{ postion: "absolute" }}>
            <h1
              style={{
                width: "100%",
                marginTop: "200px",
                fontSize: "3.5rem",
                textAlign: "center",
                color: "#fff",
                fontFamily: " Caveat,cursive",
              }}
            >
              {/* {tourTitle ? tourTitle : null} */}
            </h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
