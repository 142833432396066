import React from "react";

const PowerOfAttorney = ({ formData }) => {
  const {
    date,
    familyMembers,
    clientName,
    clientBirthDate,
    passportNumber,
    airline,
    flightDetails,
  } = formData;

  return (
    <div
      style={{
        position: "relative",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
        breakInside: "avoid",
      }}
    >
      <h1 style={{ textAlign: "center", marginBottom: "16px" }}>
        POWER OF ATTORNEY
      </h1>
      <p style={{ textAlign: "left", marginBottom: "16px" }}>{date}</p>
      <p>I/We, the undersigned,</p>
      <ol>
        {familyMembers.map((member, index) => (
          <li key={index}>
            {member.first_name} {member.last_name}, born {member.date_of_birth},
            passport number {member.passport_number}, ticket number{" "}
            {member.ticket_number}
          </li>
        ))}
      </ol>
      <p>
        <strong>Hereby authorize:</strong>
      </p>
      <p>
        {clientName}, born {clientBirthDate}, passport number {passportNumber} (
        <strong>“Representative”</strong>)
      </p>
      <p>
        to pursue claims on my behalf against {airline} for compensation for
        flight cancellation/delay/refused entry.
      </p>
      <p>Flight number: {flightDetails}</p>
      <p>
        The power of attorney covers all claims (existing and potential) that I
        may have against the airline related to the above flight. The
        Representative may conduct all legal and factual actions on my behalf
        (including to assign my claims to Linsoa Tours and Leisure Travel Inc,
        11 Main Street Monsey 10952 New York, United States), supply necessary
        documents, and receive correspondence. The Representative may also
        receive compensation on my behalf.
      </p>
      <p>
        The power of attorney should be interpreted broadly so as to cover all
        actions necessary to achieve its aim.The Representative may grant
        further powers of attorney.
      </p>
      <p>The power of attorney is subject to Polish law.</p>
      <hr style={{ margin: "20px 0" }} />
      <div style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
        {familyMembers.map((member, index) => (
          <div
            key={index}
            style={{ flex: "1 1 45%", textAlign: "center", marginTop: "20px" }}
          >
            {/* <div
              style={{
                width: "100%",
                height: "70px",
                margin: "0 auto",
                marginBottom: "10px",
              }}
            ></div> */}
            <p>________________________________</p>
            <p>
              {member.first_name} {member.last_name}
            </p>
          </div>
        ))}
      </div>
      {/* <hr style={{ margin: "20px 0" }} /> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
        }}
      >
        <div style={{ flex: "1" }}>
          <p>Foglar Law Firm (NIP: 7343586718)</p>
        </div>
        <div style={{ flex: "1", textAlign: "center" }}>
          <p>www.foglar.pl</p>
        </div>
        <div style={{ flex: "1", textAlign: "right" }}>
          <p>claims@foglar.pl</p>
        </div>
      </div> */}
    </div>
  );
};

export default PowerOfAttorney;
