import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: "",
  clientName: "",
  clientAddress: "",
  passportNumber: "",
  airline: "",
  flightDetails: "",
  clientEmail: "",
  bookingNumber: "",
  clientBirthDate: "",
  familyMembers: [],
};

const claimFormSlice = createSlice({
  name: "claimForm",
  initialState,
  reducers: {
    setClaimFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    removeClaimFormTraveler: (state, action) => {
      const index = action.payload;
      state.familyMembers = state.familyMembers.filter((_, i) => i !== index);
    },
  },
});

export const { setClaimFormData, removeClaimFormTraveler } =
  claimFormSlice.actions;

export default claimFormSlice.reducer;
